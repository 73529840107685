import React, { ReactElement } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import { useResizeDetector } from 'react-resize-detector';
import { Pagination, PaginationProps } from '../Pagination';

export type PaginationResponsiveProps = {
  widthDivisor?: number;
} & Omit<PaginationProps, 'maxShownPages'>;

const DEFAULT_WIDTH_DIVISOR = 46;

export const PaginationResponsive = ({
  widthDivisor = DEFAULT_WIDTH_DIVISOR,
  ...rest
}: PaginationResponsiveProps): ReactElement | null => {
  widthDivisor = widthDivisor < 1 ? DEFAULT_WIDTH_DIVISOR : widthDivisor;
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    handleWidth: true,
    refreshMode: 'debounce',
    refreshRate: 0,
  });

  return (
    <Skeleton
      isLoaded={!!width}
      endColor={'white'}
      speed={0}
      fadeDuration={0.4}
    >
      <Box ref={ref}>
        <Pagination
          maxShownPages={
            width === undefined ? undefined : width / widthDivisor - 2
          }
          {...rest}
        />
      </Box>
    </Skeleton>
  );
};
