import React, { ReactElement } from 'react';
import { Divider as ChakraDivider, DividerProps } from '@chakra-ui/react';

export const Divider = (props: DividerProps): ReactElement => (
  <ChakraDivider
    backgroundColor={'pinkTrillium'}
    height={3}
    marginY={'2em'}
    {...props}
  />
);
