export const TRANS = {
  pagination: {
    en: 'Pagination',
    fi: 'Sivutus',
  },
  page: {
    en: 'Page',
    fi: 'Sivu',
  },
  previousPage: {
    en: 'Previous page',
    fi: 'Edellinen sivu',
  },
  nextPage: {
    en: 'Next page',
    fi: 'Seuraava sivu',
  },
};
