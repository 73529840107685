export const TRANS = {
  previous: {
    en: 'previous',
    fi: 'edellinen',
  },
  next: {
    en: 'next',
    fi: 'seuraava',
  },
  newer: {
    en: 'newer',
    fi: 'uudempi',
  },
  older: {
    en: 'older',
    fi: 'vanhempi',
  },
};
