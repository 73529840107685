import React, { ReactElement } from 'react';
import { AspectRatio, AspectRatioProps, Box } from '@chakra-ui/react';
import { EmbedProps } from '.';

export type EmbedWithoutCookiesProps = Pick<EmbedProps, 'src' | 'title'> &
  Omit<AspectRatioProps, 'as'>;

export const EmbedWithoutCookies = ({
  src,
  title,
  ...rest
}: EmbedWithoutCookiesProps): ReactElement | null => (
  <AspectRatio {...rest}>
    <Box
      as={'iframe'}
      allowFullScreen
      frameBorder={0}
      src={src}
      title={title}
    />
  </AspectRatio>
);
