import React, { ReactElement, ReactNode } from 'react';
import { Box, HStack, VisuallyHidden } from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Link } from '../Link';
import { Superscript } from '../Superscript';
import { TRANS } from './misc';

export type FootnoteOriginProps = {
  componentLocale?: Locale;
  number: number;
};

const Origin = ({
  componentLocale,
  number,
}: FootnoteOriginProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return (
    <Superscript fontStyle={'normal'} lang={lang}>
      <Link
        href={`#${TRANS.idDestination[locale]}${number}`}
        id={`${TRANS.idOrigin[locale]}${number}`}
        fontWeight={700}
        position={'relative'}
        _before={{
          content: '""',
          position: 'absolute',
          top: '-0.1em',
          left: '-0.5em',
          height: '1.5em',
          width: '1.5em',
        }}
      >
        <VisuallyHidden>{TRANS.footnote[locale]}</VisuallyHidden>
        {number}
      </Link>
    </Superscript>
  );
};

export type FootnoteDestinationProps = {
  componentLocale?: Locale;
  number: number;
  children: ReactNode;
};

const Destination = ({
  componentLocale,
  number,
  children,
}: FootnoteDestinationProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return (
    <HStack align={'baseline'} lang={lang}>
      <Box>
        <Link
          href={`#${TRANS.idOrigin[locale]}${number}`}
          id={`${TRANS.idDestination[locale]}${number}`}
        >
          {number}.
        </Link>
      </Box>
      <Box>{children}</Box>
    </HStack>
  );
};

export const Footnote = { Origin, Destination };
