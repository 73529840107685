export const PAGE_VARIANTS = [
  'frontpage',
  'generic',
  'blogCollection',
  'eventCollection',
  'blogPost',
  'eventPost',
] as const;
export type PageVariant = typeof PAGE_VARIANTS[number];

export const COLLECTION_PAGE_VARIANTS = [
  'blogCollection',
  'eventCollection',
] as const;
export type CollectionPageVariant = typeof COLLECTION_PAGE_VARIANTS[number];

export const POST_PAGE_VARIANTS = ['blogPost', 'eventPost'] as const;
export type PostPageVariant = typeof POST_PAGE_VARIANTS[number];

export const PAGES_MAP = {
  frontpage: 'Frontpage',
  generic: 'Generic page',
  blogCollection: 'Blog Collection page',
  blogPost: 'Blog Post page',
  eventCollection: 'Event Collection page',
  eventPost: 'Event Post page',
};

export const TRANS = {
  upcoming: {
    en: 'Upcoming',
    fi: 'Tulossa',
  },
  past: {
    en: 'Past',
    fi: 'Menneet',
  },
};
