import React, { ReactElement, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { BlogPostQuery } from '../../templates/BlogPost';
import { useLocale } from '../../utils/hooks';
import { By } from '../By';
import { DateTime } from '../DateTime';
import { Hyphenate } from '../Hyphenate';
import { LinkCard } from '../LinkCard';
import { NestedLocale } from '../NestedLocale';
import { LinkCardBlogPostVariant } from './misc';

export type LinkCardBlogPostProps = {
  variant?: LinkCardBlogPostVariant;
  parentSlug?: string;
} & Pick<
  BlogPostQuery['contentfulPageBlogPost'],
  | 'slug'
  | 'language'
  | 'title'
  | 'createdAt'
  | 'authors'
  | 'authorsOther'
  | 'image'
>;

export const LinkCardBlogPost = ({
  variant = 'wide',
  parentSlug,
  slug,
  language,
  title,
  createdAt,
  authors,
  authorsOther,
  image,
}: LinkCardBlogPostProps): ReactElement => {
  const { locale } = useLocale(undefined);
  const href = useMemo(
    () =>
      parentSlug
        ? ['', locale, parentSlug, slug].join('/')
        : ['', locale, slug].join('/'),
    [locale, parentSlug, slug]
  );
  const authorNames = useMemo(
    () =>
      (authors || []).map((author) => author.name).concat(authorsOther || []),
    [authors, authorsOther]
  );
  return (
    <Box
      {...(variant === 'tallWithoutImage' || variant === 'wideWithoutImage'
        ? {
            borderColor: 'aquaOrchid',
            borderBottomWidth: 3,
            minHeight: '7em',
          }
        : {})}
    >
      <LinkCard
        href={href}
        title={
          <Box fontSize={'1.3rem'} marginBottom={'0.5em'}>
            <NestedLocale componentLocale={language}>
              <Hyphenate>{title}</Hyphenate>
            </NestedLocale>
          </Box>
        }
        subtitle={<DateTime start={createdAt} dateOnly condensed />}
        tagline={<By authors={authorNames} />}
        image={
          variant === 'tallWithoutImage' || variant === 'wideWithoutImage'
            ? undefined
            : image
        }
        imagePosition={
          variant === 'tall' || variant === 'tallWithoutImage' ? 'top' : 'left'
        }
        breakpoint={'s'}
        borderPosition={'top'}
        borderWidth={'0.5em'}
        extraSx={{
          base: {
            'height': '100%',
            '& .text': { height: '100%' },
          },
        }}
      />
    </Box>
  );
};
