import React, { ReactElement, ReactNode } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {
  MARKS,
  BLOCKS,
  INLINES,
  Node,
  Block,
} from '@contentful/rich-text-types';
import { Box } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useLocale } from '../../../utils/hooks';
import { Code } from '../../Code';
import { Emphasis } from '../../Emphasis';
import { Level, Levels } from '../../HeadingForRichText/misc';
import { Link } from '../../Link';
import { ListItem } from '../../ListItem';
import { List } from '../../List';
import { Strong } from '../../Strong';
import { RichTextData, RichTextOptions } from '..';
import { TRANS } from './misc';

type HeadingObject = {
  id: string;
  node: Block;
  [key: number]: HeadingObject;
};

type Headings = { [key: number]: HeadingObject };

export type TableOfContentsProps = {
  data: RichTextData;
};

const options: RichTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => <Strong>{text}</Strong>,
    [MARKS.ITALIC]: (text: ReactNode) => <Emphasis>{text}</Emphasis>,
    [MARKS.CODE]: (text: ReactNode) => <Code>{text}</Code>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <span>{children}</span>,
    [INLINES.EMBEDDED_ENTRY]: () => null,
  },
};

type TableOfContentsLevelProps = { data: Headings };

const TableOfContentsLevel = ({ data }: TableOfContentsLevelProps) => (
  <List listStyleType={'none'}>
    {Object.values(data).map(({ id, node, ...rest }) => (
      <ListItem key={id}>
        <Link href={`#${id}`} color={'tealCarnation'}>
          <ArrowForwardIcon
            aria-hidden
            marginEnd={'0.2em'}
            marginTop={'-0.2em'}
          />
          {renderRichText(
            {
              raw: JSON.stringify(node),
              references: [],
            },
            options
          )}
        </Link>
        {rest && <TableOfContentsLevel data={{ ...rest }} />}
      </ListItem>
    ))}
  </List>
);

export const TableOfContents = ({
  data,
}: TableOfContentsProps): ReactElement | null => {
  const { locale } = useLocale(undefined);
  const parsed = JSON.parse(data.raw || '');

  if (!parsed) {
    return null;
  }

  const nodes = parsed.content.filter((node: Node) =>
    node.nodeType.includes('heading')
  ) as Block[];

  if (nodes.length === 0) {
    return null;
  }

  const levels: Levels = [-1, -1, 0, 0, 0];
  const headings: Headings = {};

  nodes.forEach((node) => {
    const nodeType = node.nodeType;
    const firstZero = levels.indexOf(0);
    let level = parseInt(nodeType[nodeType.length - 1]);

    if (firstZero >= 2 && firstZero < level) {
      level = firstZero as Level;
    }

    levels[level] = levels[level] + 1;

    const a = levels[2];
    const b = levels[3];
    const c = levels[4];

    if (level === 2) {
      headings[a] = {
        id: `${a}`,
        node: { ...node, nodeType: BLOCKS.PARAGRAPH },
      };
    } else if (level === 3) {
      headings[a][b] = {
        id: `${a}.${b}`,
        node: { ...node, nodeType: BLOCKS.PARAGRAPH },
      };
    } else {
      headings[a][b][c] = {
        id: `${a}.${b}.${c}`,
        node: { ...node, nodeType: BLOCKS.PARAGRAPH },
      };
    }
  });

  return (
    <Box
      borderColor={'mintRose'}
      borderStartWidth={3}
      paddingStart={'1em'}
      marginY={'1em'}
    >
      <Strong color={'tealCarnation'}>{TRANS.toc[locale]}</Strong>
      <TableOfContentsLevel data={headings} />
    </Box>
  );
};
