import React, { ReactElement } from 'react';
import { Text, TextProps, VisuallyHidden } from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { formatDateTime, formatDateTimeRange } from '../../utils/datetime';

export type DateTimeProps = {
  componentLocale?: Locale;
  condensed?: boolean;
  dateOnly?: boolean;
  start: string;
  end?: string;
  inheritColor?: boolean;
} & Omit<TextProps, 'as'>;

export const DateTime = ({
  componentLocale,
  condensed,
  dateOnly,
  start,
  end,
  inheritColor,
  ...rest
}: DateTimeProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);

  return (
    <Text
      as={'time'}
      lang={lang}
      dateTime={!end || start === end ? start : `${start}/${end}`}
      color={inheritColor ? 'inherit' : 'grayViolet'}
      fontSize={'0.9em'}
      {...rest}
    >
      {!condensed ? (
        <span>
          {!end || start === end
            ? formatDateTime(
                locale === 'en' ? 'en-GB' : locale,
                start,
                dateOnly
              )
            : formatDateTimeRange(
                locale === 'en' ? 'en-GB' : locale,
                start,
                end
              )}
        </span>
      ) : (
        <span>
          <span aria-hidden>
            {!end || start === end
              ? formatDateTime(
                  locale === 'en' ? 'en-GB' : locale,
                  start,
                  dateOnly,
                  condensed
                )
              : formatDateTimeRange(
                  locale === 'en' ? 'en-GB' : locale,
                  start,
                  end,
                  condensed
                )}
          </span>
          <VisuallyHidden>
            {!end || start === end
              ? formatDateTime(
                  locale === 'en' ? 'en-GB' : locale,
                  start,
                  dateOnly
                )
              : formatDateTimeRange(
                  locale === 'en' ? 'en-GB' : locale,
                  start,
                  end
                )}
          </VisuallyHidden>
        </span>
      )}
    </Text>
  );
};
