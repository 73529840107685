import React, { ReactElement } from 'react';
import {
  Box,
  BoxProps,
  LinkBox,
  LinkOverlay,
  Text,
  TextProps,
  VisuallyHidden,
} from '@chakra-ui/react';
import { MotionConfigProps } from 'framer-motion';
import { fluid } from '../../theme/fluid';
import { useLocale, useReducedMotion } from '../../utils/hooks';
import { prettifyUrl, protocolizeUrl } from '../../utils/string';
import { Locale } from '../../utils/types';
import { Image, ImageProps } from '../Image';
import { TRANS } from './misc';

export type FigureProps = {
  caption?: ReactElement;
  captionProps?: Omit<TextProps, 'as'>;
  componentLocale?: Locale;
  fullWidth?: boolean;
  reducedMotion?: MotionConfigProps['reducedMotion'];
  reference?: string | number;
  url?: string;
} & Pick<ImageProps, 'image' | 'textAlternative'> &
  Omit<BoxProps, 'as'>;

export const Figure = ({
  caption,
  captionProps,
  componentLocale,
  fullWidth,
  image,
  reducedMotion,
  reference,
  textAlternative,
  url,
  ...rest
}: FigureProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);
  const reduceMotion = useReducedMotion(reducedMotion);
  url = !url ? undefined : protocolizeUrl(url);

  return !image ? null : (
    <Box
      marginX={fullWidth ? 0 : fluid.zeroToHalf}
      marginY={'2em'}
      {...(url && {
        as: LinkBox,
        sx: {
          '& .figureLink': {
            position: 'relative',
            outline: 'transparent',
          },
          '& .figureLink::after': {
            borderColor: 'transparent',
            borderWidth: 3,
            content: '""',
            position: 'absolute',
            top: reduceMotion ? -6 : 0,
            right: reduceMotion ? -6 : 0,
            bottom: reduceMotion ? -6 : 0,
            left: reduceMotion ? -6 : 0,
            transitionDuration: 'normal',
            transitionProperty: 'border-color, top, right, bottom, left',
            transitionTimingFunction: 'ease-out',
            zIndex: 100,
          },
          '&:hover, &:focus-within': {
            '& .figureLink::after': {
              borderColor: 'tealCarnation',
              top: -6,
              right: -6,
              bottom: -6,
              left: -6,
            },
          },
        },
      })}
      {...rest}
    >
      <Box lang={lang} as={'figure'}>
        {url ? (
          <LinkOverlay href={url} className={'figureLink'}>
            <VisuallyHidden>
              {textAlternative || prettifyUrl(url)}
            </VisuallyHidden>
            <Image image={image} />
          </LinkOverlay>
        ) : (
          <Image image={image} textAlternative={textAlternative} />
        )}
        {(reference || caption) && (
          <Text
            as={'figcaption'}
            borderColor={'mintRose'}
            borderTopWidth={'0.25rem'}
            borderLeftWidth={'0.25rem'}
            fontSize={'0.9em'}
            paddingX={'1em'}
            paddingY={'0.5em'}
            {...captionProps}
          >
            {reference && caption ? (
              <>
                {TRANS.figure[locale]} {reference}: {caption}
              </>
            ) : reference ? (
              <>
                {TRANS.figure[locale]} {reference}
              </>
            ) : (
              caption
            )}
          </Text>
        )}
      </Box>
    </Box>
  );
};
