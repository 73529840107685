import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Center,
  Grid,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useLocale } from '../../utils/hooks';
import { positiveIntegerOrValue } from '../../utils/number';
import { EN_SPACE } from '../../utils/string';
import { Locale } from '../../utils/types';
import { Button, ButtonProps } from '../Button';
import { TRANS } from './misc';

const PAGINATION_ITEM_SX = {
  borderRadius: '50%',
  borderWidth: 2,
  fontSize: '0.8em',
  height: '2.5em',
  margin: 3,
  padding: 0,
  width: '2.5em',
};

const PaginationButton = ({ children, ...rest }: ButtonProps) => (
  <Button
    variant={'ghost'}
    {...PAGINATION_ITEM_SX}
    _activeLink={{ borderColor: 'tealCarnation' }}
    _hover={{ backgroundColor: 'mintRose' }}
    {...rest}
  >
    {children}
  </Button>
);

const PaginationEllipsis = () => (
  <Text
    {...PAGINATION_ITEM_SX}
    as={'span'}
    borderColor={'transparent'}
    color={'tealCarnation'}
    display={'inline-block'}
    textAlign={'center'}
  >
    <Text as={'span'} fontSize={'1rem'}>
      …
    </Text>
  </Text>
);

export type PaginationProps = {
  totalItems: number;
  initialPage?: number;
  itemsPerPage?: number;
  maxShownPages?: number;
  onChange?: (page: number) => void;
  componentLocale?: Locale;
};

export const Pagination = ({
  totalItems,
  initialPage,
  itemsPerPage,
  maxShownPages,
  onChange,
  componentLocale,
}: PaginationProps): ReactElement | null => {
  totalItems = positiveIntegerOrValue(totalItems, 1);
  initialPage = positiveIntegerOrValue(initialPage, 1);
  itemsPerPage = positiveIntegerOrValue(itemsPerPage, 10);
  maxShownPages = positiveIntegerOrValue(maxShownPages, 11);

  const { locale, lang } = useLocale(componentLocale);

  const totalPages = useMemo(
    () => Math.ceil(totalItems / (itemsPerPage as number)),
    [totalItems, itemsPerPage]
  );

  const pageNumbers = useMemo(
    () =>
      Array(totalPages)
        .fill(0)
        .map((_, i) => i + 1),
    [totalPages]
  );

  const [page, setPage] = useState(initialPage);

  const handlePrev = () => page > 1 && setPage(page - 1);
  const handleNext = () => page < totalPages && setPage(page + 1);
  const handlePage = (p: number) => setPage(p);

  useEffect(() => onChange && onChange(page), [onChange, page]);

  return totalPages < 2 ? null : (
    <Center>
      <Grid
        as={'nav'}
        aria-label={TRANS.pagination[locale]}
        lang={lang}
        gridTemplateColumns={'auto auto auto'}
      >
        <PaginationButton disabled={page === 1} onClick={handlePrev}>
          <ArrowLeftIcon
            aria-hidden
            fontSize={'0.7em'}
            marginInlineEnd={'0.1em'}
          />
          <VisuallyHidden>{TRANS.previousPage[locale]}</VisuallyHidden>
        </PaginationButton>
        {maxShownPages < 5 ? (
          <Box alignSelf={'center'} marginX={8}>
            <NumberInput
              aria-label={TRANS.page[locale]}
              focusBorderColor={'tealCarnation'}
              focusInputOnChange={false}
              inputMode={'numeric'}
              isValidCharacter={(value) => !!value.match(/[0-9]/)?.[0]}
              max={totalPages}
              min={1}
              onChange={(_, value) =>
                Number.isNaN(value) || value < 1
                  ? setPage(1)
                  : value > totalPages
                  ? setPage(totalPages)
                  : setPage(value)
              }
              pattern={'[0-9]*'}
              precision={0}
              value={page}
              display={'inline-block'}
            >
              <NumberInputField
                borderColor={'tealCarnation'}
                borderWidth={2}
                color={'tealCarnation'}
                fontSize={'0.8em'}
                height={'2em'}
                paddingX={'0.6em'}
                width={'5em'}
                _hover={{ borderColor: 'tealCarnation' }}
              />
              <NumberInputStepper>
                <NumberIncrementStepper
                  borderColor={'tealCarnation'}
                  borderWidth={1}
                  color={'tealCarnation'}
                  fontSize={'0.5em'}
                  _hover={{ backgroundColor: 'mintRose' }}
                />
                <NumberDecrementStepper
                  borderColor={'tealCarnation'}
                  borderWidth={1}
                  color={'tealCarnation'}
                  fontSize={'0.5em'}
                  _hover={{ backgroundColor: 'mintRose' }}
                />
              </NumberInputStepper>
            </NumberInput>
            <Box
              color={'tealCarnation'}
              display={'inline-block'}
              fontSize={'0.9em'}
            >
              {EN_SPACE}/ {totalPages}
            </Box>
          </Box>
        ) : totalPages <= maxShownPages ? (
          <Center flexWrap={'wrap'}>
            {pageNumbers.map((number) => (
              <PaginationButton
                key={`pagination-${number}`}
                aria-current={page === number ? 'page' : undefined}
                onClick={() => handlePage(number)}
              >
                {number}
              </PaginationButton>
            ))}
          </Center>
        ) : (
          <Center flexWrap={'wrap'}>
            <PaginationButton
              aria-current={page === 1 ? 'page' : undefined}
              onClick={() => handlePage(1)}
            >
              {1}
            </PaginationButton>
            {page <= Math.ceil((maxShownPages - 2) / 2) + 1 ? (
              <>
                {pageNumbers.slice(1, maxShownPages - 2).map((number) => (
                  <PaginationButton
                    key={`pagination-${number}`}
                    aria-current={page === number ? 'page' : undefined}
                    onClick={() => handlePage(number)}
                  >
                    {number}
                  </PaginationButton>
                ))}
                <PaginationEllipsis />
              </>
            ) : page >= totalPages - Math.ceil((maxShownPages - 2) / 2) ? (
              <>
                <PaginationEllipsis />
                {pageNumbers.slice(-(maxShownPages - 2), -1).map((number) => (
                  <PaginationButton
                    key={`pagination-${number}`}
                    aria-current={page === number ? 'page' : undefined}
                    onClick={() => handlePage(number)}
                  >
                    {number}
                  </PaginationButton>
                ))}
              </>
            ) : (
              <>
                <PaginationEllipsis />
                {pageNumbers
                  .slice(
                    page - Math.floor((maxShownPages - 3) / 2),
                    page + Math.floor((maxShownPages - 4) / 2)
                  )
                  .map((number) => (
                    <PaginationButton
                      key={`pagination-${number}`}
                      aria-current={page === number ? 'page' : undefined}
                      onClick={() => handlePage(number)}
                    >
                      {number}
                    </PaginationButton>
                  ))}
                <PaginationEllipsis />
              </>
            )}
            <PaginationButton
              aria-current={page === totalPages ? 'page' : undefined}
              onClick={() => handlePage(totalPages)}
            >
              {totalPages}
            </PaginationButton>
          </Center>
        )}
        <PaginationButton disabled={page === totalPages} onClick={handleNext}>
          <ArrowRightIcon
            aria-hidden
            fontSize={'0.7em'}
            marginInlineStart={'0.1em'}
          />
          <VisuallyHidden>{TRANS.nextPage[locale]}</VisuallyHidden>
        </PaginationButton>
      </Grid>
    </Center>
  );
};
