import React, { ReactElement } from 'react';
import { Box, BoxProps, VisuallyHidden } from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { isEmptyNode } from '../../utils/other';
import { Locale } from '../../utils/types';
import { TRANS } from './misc';

export type CalloutProps = { componentLocale?: Locale } & BoxProps;

export const Callout = ({
  children,
  componentLocale,
  ...rest
}: CalloutProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return isEmptyNode(children) ? null : (
    <Box
      lang={lang}
      backgroundColor={'mintRose'}
      borderColor={'tealCarnation'}
      borderLeftWidth={'0.5rem'}
      marginY={'1em'}
      paddingX={'1em'}
      paddingY={'0.8em'}
      {...rest}
    >
      <VisuallyHidden>{TRANS.attention[locale]}: </VisuallyHidden>
      {children}
    </Box>
  );
};
