import React, { ReactElement } from 'react';
import { useConsentContext } from '../../context/ConsentContext';
import { fluid } from '../../theme/fluid';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Embed, EmbedProps } from '../Embed';
import { LOCAL_STORAGE_ITEM, MAP, REGEX, Size, TRANS } from './misc';

export type EmbedSpotifyProps = {
  componentLocale?: Locale;
  fullWidth?: boolean;
  size?: Size;
  url: string;
} & Omit<
  EmbedProps,
  | 'src'
  | 'consent'
  | 'setConsent'
  | 'consentTitle'
  | 'consentParagraph'
  | 'consentButton'
  | 'marginX'
  | 'height'
>;

export const EmbedSpotify = ({
  componentLocale,
  fullWidth,
  size = 'small',
  url,
  ...rest
}: EmbedSpotifyProps): ReactElement | null => {
  const { locale } = useLocale(componentLocale);
  const { spotify } = useConsentContext();
  const { consent, setConsent } = spotify;

  const match = url?.match(REGEX);
  const trimmedUrl = match?.[1];
  const src = trimmedUrl
    ? `https://open.spotify.com/embed/${trimmedUrl}?theme=0`
    : '';

  const height = MAP[size];

  return !src ? null : (
    <Embed
      src={src}
      consent={consent}
      setConsent={setConsent}
      consentTitle={TRANS.consentTitle[locale]}
      consentParagraph={TRANS.consentParagraph[locale]}
      consentButton={TRANS.consentButton[locale]}
      localStorageItem={LOCAL_STORAGE_ITEM}
      height={height}
      marginX={fullWidth ? 0 : fluid.zeroToHalf}
      marginY={'2em'}
      {...rest}
    />
  );
};
