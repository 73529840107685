import React, { ReactElement } from 'react';
import {
  Box,
  Center,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaQuoteLeft } from 'react-icons/fa';
import { useLocale } from '../../utils/hooks';
import { Button } from '../Button';
import { Figure, FigureProps } from '../Figure';
import { Hyphenate } from '../Hyphenate';
import { SmartGrid } from '../SmartGrid';
import { TRANS, SIZES } from './misc';

export type FigureGridProps = {
  data?: FigureProps[];
  size?: 'tiny' | 'small' | 'medium' | 'large';
};

export const FigureGrid = ({
  data = [],
  size = 'medium',
}: FigureGridProps): ReactElement | null => {
  const { locale } = useLocale(undefined);

  return !data.length ? null : (
    <Center marginY={'2em'}>
      <SmartGrid {...SIZES[size]} gap={'1em'}>
        {data.map(({ reference, caption, ...rest }, i) => (
          <Figure
            key={i}
            fullWidth
            marginY={0}
            {...(!reference && !caption
              ? {}
              : size === 'tiny' || size === 'small'
              ? {
                  caption: (
                    <Popover isLazy placement={'bottom-start'}>
                      <PopoverTrigger>
                        <Button
                          variant={'unstyled'}
                          borderRadius={0}
                          fontSize={'0.5em'}
                          marginTop={9}
                          verticalAlign={'baseline'}
                          width={'100%'}
                          _hover={{
                            backgroundColor: 'mintRose',
                            borderColor: 'mintRose',
                          }}
                          _expanded={{
                            backgroundColor: 'mintRose',
                            borderColor: 'mintRose',
                          }}
                        >
                          <FaQuoteLeft />
                          <VisuallyHidden>
                            {TRANS.showCaption[locale]}
                          </VisuallyHidden>
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent
                        as={Box}
                        borderColor={'mintRose'}
                        borderRadius={0}
                        borderWidth={3}
                        width={'auto'}
                        maxWidth={{
                          base: 'calc(100vw - 1rem)',
                          m: '50vw',
                        }}
                        variants={{
                          exit: {
                            height: 0,
                            overflowY: 'hidden',
                            transition: {
                              height: {
                                duration: 0.2,
                                ease: [0.25, 0.1, 0.25, 1],
                              },
                              overflowY: { duration: -1 },
                            },
                          },
                          enter: {
                            height: 'auto',
                            overflowY: 'auto',
                            transition: {
                              height: {
                                duration: 0.3,
                                ease: [0.25, 0.1, 0.25, 1],
                              },
                              overflowY: { delay: 0.4, duration: 0 },
                            },
                          },
                        }}
                      >
                        <Box marginX={'1em'} marginY={'0.5em'}>
                          {reference && caption ? (
                            <>
                              {TRANS.figure[locale]} {reference}: {caption}
                            </>
                          ) : reference ? (
                            <>
                              {TRANS.figure[locale]} {reference}
                            </>
                          ) : (
                            caption
                          )}
                        </Box>
                      </PopoverContent>
                    </Popover>
                  ),
                  captionProps: {
                    borderWidth: 0,
                    padding: 0,
                  },
                }
              : {
                  caption: <Hyphenate>{caption}</Hyphenate>,
                  reference: reference,
                })}
            {...rest}
          />
        ))}
      </SmartGrid>
    </Center>
  );
};
