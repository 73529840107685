import React, { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocale } from '../../../utils/hooks';
import { Divider } from '../../Divider';
import { Footnote } from '../../Footnote';
import { List } from '../../List';
import { ListItem } from '../../ListItem';
import { Strong } from '../../Strong';
import { RichText, RichTextData } from '..';
import { TRANS } from './misc';

export type FootnotesProps = {
  data?: RichTextData[];
};

export const Footnotes = ({ data }: FootnotesProps): ReactElement | null => {
  const { locale } = useLocale(undefined);

  return !data || !data.length ? null : (
    <Box>
      <Divider marginTop={'4em'} />
      <Strong>{TRANS.footnotes[locale]}:</Strong>
      <List ordered listStyleType={'none'} marginStart={0}>
        {data.map((footnote, i) => (
          <ListItem key={`footnote${i + 1}`}>
            <Footnote.Destination number={i + 1}>
              <RichText data={footnote} />
            </Footnote.Destination>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
