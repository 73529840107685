import { TRANS as FIGURE_TRANS } from '../Figure/misc';

export const TRANS = {
  figure: FIGURE_TRANS.figure,
  showCaption: { en: 'Show caption', fi: 'Näytä kuvateksti' },
};

export const SIZES = {
  tiny: {
    minColumnWidth: 25,
    maxColumnWidth: 50,
  },
  small: {
    minColumnWidth: 50,
    maxColumnWidth: 100,
  },
  medium: {
    minColumnWidth: 150,
    maxColumnWidth: 250,
  },
  large: {
    minColumnWidth: 300,
  },
};
