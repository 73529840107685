import React, { ReactElement, ReactNode } from 'react';
import GatsbyLink from 'gatsby-link';
import {
  Box,
  CSSObject,
  Flex,
  LinkBox,
  LinkOverlay,
  Spacer,
} from '@chakra-ui/react';
import { MotionConfigProps } from 'framer-motion';
import { useReducedMotion } from '../../utils/hooks';
import { positiveOrZero } from '../../utils/number';
import { Image, ImageProps } from '../Image';

export type LinkCardProps = {
  href: string;
  title: ReactNode;
  subtitle?: ReactNode;
  tagline?: ReactNode;
  image?: ImageProps['image'];
  imagePosition?: 'top' | 'right' | 'bottom' | 'left';
  breakpoint?: 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl';
  borderColor?: string;
  borderPosition?: 'top' | 'right' | 'bottom' | 'left';
  borderWidth?: string | number;
  keepTextColor?: boolean;
  reducedMotion?: MotionConfigProps['reducedMotion'];
  extraSx?: {
    base?: CSSObject;
    interaction?: CSSObject;
    interactionFallback?: CSSObject;
  };
};

export const LinkCard = ({
  href,
  title,
  subtitle,
  tagline,
  image,
  imagePosition = 'left',
  breakpoint = 's',
  borderColor = 'aquaOrchid',
  borderPosition = 'top',
  borderWidth = '0.5em',
  keepTextColor = true,
  extraSx,
  reducedMotion,
}: LinkCardProps): ReactElement => {
  borderWidth = positiveOrZero(borderWidth);

  const commonSx = {
    content: '""',
    borderTopWidth: borderPosition === 'top' ? borderWidth : 0,
    borderRightWidth: borderPosition === 'right' ? borderWidth : 0,
    borderBottomWidth: borderPosition === 'bottom' ? borderWidth : 0,
    borderLeftWidth: borderPosition === 'left' ? borderWidth : 0,
    position: 'absolute',
    transitionDuration: 'slow',
    transitionTimingFunction: 'ease-in-out',
  };

  const fadeSx = {
    base: {
      ...commonSx,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      transitionProperty: 'background-color',
    },
  };

  const slideSx = {
    base: {
      ...commonSx,
      top: borderPosition === 'bottom' ? '100%' : 0,
      right: borderPosition === 'left' ? '100%' : 0,
      bottom: borderPosition === 'top' ? '100%' : 0,
      left: borderPosition === 'right' ? '100%' : 0,
      marginTop: borderPosition === 'bottom' ? '-' + borderWidth : 0,
      marginLeft: borderPosition === 'right' ? '-' + borderWidth : 0,
      transitionProperty: 'top, right, bottom, left',
    },
    interaction: {
      top: borderPosition === 'bottom' ? borderWidth : 0,
      right: 0,
      bottom: 0,
      left: borderPosition === 'right' ? borderWidth : 0,
    },
  };

  const reduceMotion = useReducedMotion(reducedMotion);

  return (
    <LinkBox
      cursor={'pointer'}
      sx={
        reduceMotion
          ? {
              '&::before': {
                ...fadeSx.base,
                backgroundColor: 'white',
                borderColor: borderColor,
                zIndex: -1,
              },
              '& .text': {
                transitionDuration: 'slow',
              },
              ...extraSx?.base,
              '&:hover, &:focus-within': {
                '&::before': { backgroundColor: borderColor },
                '& .text *': {
                  color: keepTextColor ? undefined : 'white',
                },
                ...extraSx?.interaction,
                ...extraSx?.interactionFallback,
              },
            }
          : keepTextColor
          ? {
              '&::before': {
                ...slideSx.base,
                backgroundColor: borderColor,
                borderColor: borderColor,
                zIndex: -1,
              },
              ...extraSx?.base,
              '&:hover, &:focus-within': {
                '&::before': slideSx.interaction,
                ...extraSx?.interaction,
                '& .glitch-left': {
                  opacity: 0.3,
                  transform: 'translate(-0.25rem) !important',
                },
                '& .glitch-right': {
                  opacity: 0.6,
                  transform: 'translate(0.25rem) !important',
                },
              },
            }
          : {
              '@supports not ((mix-blend-mode: difference) and (mix-blend-mode: screen) and (mix-blend-mode: soft-light))':
                {
                  '&::before': {
                    ...slideSx.base,
                    backgroundColor: borderColor,
                    borderColor: borderColor,
                    zIndex: -1,
                  },
                  '& .text': {
                    transitionDuration: 'faster',
                  },
                  ...extraSx?.base,
                  '&:hover, &:focus-within': {
                    '&::before': slideSx.interaction,
                    '& .text *': {
                      color: 'white',
                    },
                    ...extraSx?.interaction,
                    ...extraSx?.interactionFallback,
                  },
                },
              '@supports ((mix-blend-mode: difference) and (mix-blend-mode: screen) and (mix-blend-mode: soft-light))':
                {
                  'backgroundColor': 'white',
                  '&::before': {
                    ...slideSx.base,
                    backgroundColor: 'white',
                    borderColor: 'white',
                    mixBlendMode: 'difference',
                  },
                  '&::after': {
                    ...slideSx.base,
                    backgroundColor: borderColor,
                    borderColor: borderColor,
                    mixBlendMode: 'screen',
                  },
                  '& .text::after': {
                    ...slideSx.base,
                    backgroundColor: 'white',
                    borderColor: 'white',
                    mixBlendMode: 'soft-light',
                  },
                  ...extraSx?.base,
                  '&:hover, &:focus-within': {
                    '&::before, &::after, & .text::after': slideSx.interaction,
                    ...extraSx?.interaction,
                  },
                },
            }
      }
    >
      <Flex
        direction={
          imagePosition === 'top'
            ? 'column'
            : imagePosition === 'right'
            ? { base: 'column', [breakpoint]: 'row-reverse' }
            : imagePosition === 'bottom'
            ? 'column-reverse'
            : { base: 'column', [breakpoint]: 'row' }
        }
        paddingTop={borderPosition === 'top' ? borderWidth : 0}
        paddingRight={borderPosition === 'right' ? borderWidth : 0}
        paddingBottom={borderPosition === 'bottom' ? borderWidth : 0}
        paddingLeft={borderPosition === 'left' ? borderWidth : 0}
        height={'100%'}
        width={'100%'}
      >
        {image && (
          <Flex
            className={'image'}
            flexShrink={0}
            pointerEvents={'none'}
            position={'relative'}
            width={
              imagePosition === 'right' || imagePosition === 'left'
                ? { base: '100%', [breakpoint]: '10em' }
                : '100%'
            }
            zIndex={100}
            sx={{
              '& img': {
                objectFit: 'cover',
              },
            }}
          >
            <Image image={image} />
            <Flex
              className={'glitch-left'}
              backgroundColor={'pinkTrillium'}
              opacity={0.15}
              position={'absolute'}
              top={0}
              right={0}
              bottom={0}
              left={0}
              transitionProperty={'opacity, transform'}
              transitionDuration={'normal'}
              sx={{
                '& img': {
                  objectFit: 'cover',
                  filter: 'grayscale(100%) contrast(200%)',
                  mixBlendMode: 'screen',
                },
              }}
            >
              <Image image={image} />
            </Flex>
            <Flex
              className={'glitch-right'}
              backgroundColor={'pinkTrillium'}
              opacity={0.15}
              position={'absolute'}
              top={0}
              right={0}
              bottom={0}
              left={0}
              transitionProperty={'opacity, transform'}
              transitionDuration={'normal'}
              sx={{
                '& img': {
                  objectFit: 'cover',
                  filter: 'grayscale(100%) contrast(200%)',
                  mixBlendMode: 'screen',
                },
              }}
            >
              <Image image={image} />
            </Flex>
          </Flex>
        )}
        <Flex
          className={'text'}
          direction={'column'}
          minWidth={0}
          paddingX={'1em'}
          paddingY={'0.8em'}
          textAlign={{ base: 'center', [breakpoint]: 'initial' }}
          width={'100%'}
        >
          <Box fontSize={'1.5em'} lineHeight={1.2}>
            <LinkOverlay as={GatsbyLink} to={href}>
              {title}
            </LinkOverlay>
          </Box>
          <Spacer />
          <Box>{subtitle}</Box>
          <Box>{tagline}</Box>
        </Flex>
      </Flex>
    </LinkBox>
  );
};
