import React, { ReactElement, useMemo } from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import { Locale } from '../../../utils/types';
import { ComingSoon } from '../../ComingSoon';
import { LinksPrevNext } from '../../LinksPrevNext';
import { NestedLocale } from '../../NestedLocale';
import { RichText, RichTextData } from '../../RichText';
import { PageVariant, TRANS } from '../misc';
import { PaginatedGrid } from '../../PaginatedGrid';
import {
  LinkCardBlogPost,
  LinkCardBlogPostProps,
} from '../../LinkCardBlogPost';
import {
  LinkCardEventPost,
  LinkCardEventPostProps,
} from '../../LinkCardEventPost';
import { Heading } from '../../Heading';
import { usePageContext } from '../../../context/PageContext';

type ContentAreaProps = {
  variant: PageVariant;
  content?: RichTextData;
  showTableOfContents?: boolean;
  blogPosts?: LinkCardBlogPostProps[];
  eventPosts?: LinkCardEventPostProps[];
  parentSlug?: string;
  withoutCardImages?: boolean;
  language?: Locale;
  previousPath?: string;
  nextPath?: string;
};

export const ContentArea = ({
  variant,
  content,
  showTableOfContents,
  language,
  previousPath,
  nextPath,
  parentSlug,
  blogPosts,
  eventPosts,
  withoutCardImages,
}: ContentAreaProps): ReactElement => {
  const { locale } = usePageContext();

  const upcoming = useMemo(
    () =>
      !eventPosts
        ? []
        : eventPosts
            .filter((post) => new Date(post.end).getTime() - Date.now() > 0)
            .reverse(),
    [eventPosts]
  );

  const past = useMemo(
    () =>
      !eventPosts
        ? []
        : eventPosts?.filter(
            (post) => new Date(post.end).getTime() - Date.now() <= 0
          ),
    [eventPosts]
  );

  return (
    <>
      {(variant === 'frontpage' || variant === 'generic') && (
        <>
          {!content?.raw ? (
            <ComingSoon />
          ) : (
            <RichText
              data={content}
              showTableOfContents={showTableOfContents}
            />
          )}
        </>
      )}
      {variant === 'blogCollection' && (
        <>
          {!blogPosts?.length ? (
            <ComingSoon />
          ) : (
            <Box marginY={'2em'}>
              <PaginatedGrid
                itemsPerPage={12}
                items={blogPosts.map((post, i) => (
                  <LinkCardBlogPost
                    key={`blogPost-${i}`}
                    variant={withoutCardImages ? 'wideWithoutImage' : 'wide'}
                    parentSlug={parentSlug}
                    {...post}
                  />
                ))}
                gap={'2em'}
                gridTemplateColumns={{
                  base: '100%',
                  s: withoutCardImages
                    ? 'repeat(auto-fit, minmax(18em, 1fr))'
                    : 'repeat(auto-fit, minmax(24em, 1fr))',
                }}
                gridAutoRows={{ base: 'auto', s: '1fr' }}
              />
            </Box>
          )}
        </>
      )}
      {variant === 'eventCollection' && (
        <>
          {!eventPosts?.length ? (
            <ComingSoon />
          ) : (
            <Box marginY={'2em'}>
              {!upcoming.length ? null : (
                <>
                  <Heading level={4} as={'h2'} marginBottom={'1em'}>
                    {TRANS.upcoming[locale]}
                  </Heading>
                  <PaginatedGrid
                    itemsPerPage={12}
                    items={upcoming.map((post, i) => (
                      <LinkCardEventPost
                        key={`upcoming-${i}`}
                        variant={
                          withoutCardImages ? 'wideWithoutImage' : 'wide'
                        }
                        parentSlug={parentSlug}
                        {...post}
                      />
                    ))}
                    gap={'2em'}
                    gridTemplateColumns={{
                      base: '100%',
                      s: withoutCardImages
                        ? 'repeat(auto-fit, minmax(18em, 1fr))'
                        : 'repeat(auto-fit, minmax(24em, 1fr))',
                    }}
                    gridAutoRows={{ base: 'auto', s: '1fr' }}
                  />
                </>
              )}
              {!past.length ? null : (
                <>
                  <Heading level={4} as={'h2'} marginBottom={'1em'}>
                    {TRANS.past[locale]}
                  </Heading>
                  <PaginatedGrid
                    itemsPerPage={12}
                    items={past.map((post, i) => (
                      <LinkCardEventPost
                        key={`past-${i}`}
                        variant={
                          withoutCardImages
                            ? 'wideWithoutImageCondensed'
                            : 'wideCondensed'
                        }
                        parentSlug={parentSlug}
                        {...post}
                      />
                    ))}
                    gap={'2em'}
                    gridTemplateColumns={{
                      base: '100%',
                      s: withoutCardImages
                        ? 'repeat(auto-fit, minmax(18em, 1fr))'
                        : 'repeat(auto-fit, minmax(24em, 1fr))',
                    }}
                    gridAutoRows={{ base: 'auto', s: '1fr' }}
                  />
                </>
              )}
            </Box>
          )}
        </>
      )}
      {(variant === 'blogPost' || variant === 'eventPost') && (
        <Flex direction={'column'}>
          {!content?.raw ? (
            <ComingSoon />
          ) : (
            <NestedLocale componentLocale={language}>
              <RichText
                data={content}
                showTableOfContents={showTableOfContents}
              />
            </NestedLocale>
          )}
          <Spacer />
          <LinksPrevNext
            variant={'newer-older'}
            prev={previousPath}
            next={nextPath}
            marginTop={'2em'}
          />
        </Flex>
      )}
    </>
  );
};
