import React, { ReactElement } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { localizeList } from '../../utils/string';
import { List } from '../List';
import { ListItem } from '../ListItem';
import { TRANS } from './misc';

export type ByProps = {
  asList?: boolean;
  authors?: string[];
  componentLocale?: Locale;
};

export const By = ({
  asList,
  authors,
  componentLocale,
}: ByProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return !authors || !authors.length ? null : (
    <Box lang={lang} fontSize={'0.9em'}>
      {authors.length === 1 ? (
        <Text>
          {TRANS.author[locale]}: {authors[0]}
        </Text>
      ) : !asList ? (
        <Text>
          {TRANS.authors[locale]}: {localizeList([...authors], locale)}
        </Text>
      ) : (
        <>
          <Text>{TRANS.authors[locale]}:</Text>
          <List>
            {authors.map((author, i) => (
              <ListItem key={`${author}-${i}`}>{author}</ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};
