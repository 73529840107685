import React, { ReactElement } from 'react';
import { useConsentContext } from '../../context/ConsentContext';
import { fluid } from '../../theme/fluid';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import {
  AspectRatio as AspectRatioType,
  LOCAL_STORAGE_ITEM,
  MAP,
  REGEX,
  TRANS,
} from './misc';
import { Embed, EmbedProps } from '../Embed';

export type EmbedYouTubeProps = {
  aspectRatio?: AspectRatioType;
  componentLocale?: Locale;
  fullWidth?: boolean;
  url: string;
} & Omit<
  EmbedProps,
  | 'src'
  | 'consent'
  | 'setConsent'
  | 'consentTitle'
  | 'consentParagraph'
  | 'consentButton'
  | 'marginX'
  | 'ratio'
>;

export const EmbedYouTube = ({
  aspectRatio = '16:9',
  componentLocale,
  fullWidth,
  url,
  ...rest
}: EmbedYouTubeProps): ReactElement | null => {
  const { locale } = useLocale(componentLocale);
  const { youTube } = useConsentContext();
  const { consent, setConsent } = youTube;

  const match = url?.match(REGEX);
  const videoId = match?.[1];
  const listId = match?.[2];
  const src =
    videoId && listId
      ? `https://www.youtube-nocookie.com/embed/${videoId}?hl=${locale}&modestbranding=1&controls=0&rel=0&list=${listId}`
      : videoId
      ? `https://www.youtube-nocookie.com/embed/${videoId}?hl=${locale}&modestbranding=1&controls=0&rel=0`
      : '';

  const ratio = MAP[aspectRatio];

  return !src ? null : (
    <Embed
      src={src}
      consent={consent}
      setConsent={setConsent}
      consentTitle={TRANS.consentTitle[locale]}
      consentParagraph={TRANS.consentParagraph[locale]}
      consentButton={TRANS.consentButton[locale]}
      localStorageItem={LOCAL_STORAGE_ITEM}
      ratio={ratio}
      marginX={fullWidth ? 0 : fluid.zeroToHalf}
      marginY={'2em'}
      {...rest}
    />
  );
};
