import React, { ReactElement, ReactNode } from 'react';
import { Heading } from '../Heading';
import { Level, Levels } from './misc';

export type HeadingForRichTextProps = {
  children: ReactNode;
  level: Level;
  levels?: Levels;
};

export const HeadingForRichText = ({
  children,
  level: originalLevel,
  levels,
}: HeadingForRichTextProps): ReactElement => {
  let level = originalLevel;
  let id;

  if (levels?.length) {
    const firstZero = levels.indexOf(0);

    if (firstZero >= 2 && firstZero < level) {
      level = firstZero as Level;
    }

    levels[level] = levels[level] + 1;

    id = levels.slice(2, level + 1).join('.');
  }

  return (
    <Heading
      id={id}
      as={`h${level}`}
      level={(level + 2) as 4 | 5 | 6}
      tabIndex={id ? -1 : undefined}
      outlineColor={'transparent'}
      _focus={{ boxShadow: 'outline' }}
    >
      {children}
    </Heading>
  );
};
