import React, { ReactElement, ReactNode, useState } from 'react';
import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { positiveIntegerOrValue } from '../../utils/number';
import { EN_DASH } from '../../utils/string';
import { Locale } from '../../utils/types';
import { ComingSoon } from '../ComingSoon';
import { PaginationResponsive } from '../PaginationResponsive';
import { Paragraph } from '../Paragraph';
import { TRANS } from './misc';

export type PaginatedGridProps = {
  items: ReactNode[];
  itemsPerPage?: number;
  componentLocale?: Locale;
} & Omit<SimpleGridProps, 'children'>;

export const PaginatedGrid = ({
  items,
  itemsPerPage = 10,
  componentLocale,
  ...rest
}: PaginatedGridProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);

  itemsPerPage = positiveIntegerOrValue(itemsPerPage, 10);
  const totalItems = items?.length || 0;

  const [page, setPage] = useState(1);
  const handleChange = (newPage: number) => setPage(newPage);

  return !totalItems ? (
    <ComingSoon componentLocale={lang} />
  ) : (
    <>
      <Paragraph lang={lang} fontSize={'0.9em'} color={'grayViolet'}>
        {totalItems === 1 ? (
          <>1 / 1 {TRANS.items[locale]}</>
        ) : (
          <>
            {1 + (page - 1) * itemsPerPage}
            {EN_DASH}
            {Math.min(page * itemsPerPage, totalItems)} / {totalItems}{' '}
            {TRANS.items[locale]}
          </>
        )}
      </Paragraph>
      <SimpleGrid gap={'0.5em'} marginBottom={'2em'} {...rest}>
        {items.slice((page - 1) * itemsPerPage, page * itemsPerPage)}
      </SimpleGrid>
      <PaginationResponsive
        totalItems={totalItems}
        onChange={handleChange}
        itemsPerPage={itemsPerPage}
        componentLocale={lang}
      />
    </>
  );
};
