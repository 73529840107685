export const TRANS = {
  item: {
    en: 'item',
    fi: 'kohde',
  },
  items: {
    en: 'items',
    fi: 'kohdetta',
  },
};
