import React, { ReactElement } from 'react';
import { useConsentContext } from '../../context/ConsentContext';
import { fluid } from '../../theme/fluid';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Embed, EmbedProps } from '../Embed';
import { LOCAL_STORAGE_ITEM, REGEX, TRANS } from './misc';

export type EmbedGoogleMapsProps = {
  componentLocale?: Locale;
  fullWidth?: boolean;
  url: string;
} & Omit<
  EmbedProps,
  | 'src'
  | 'consent'
  | 'setConsent'
  | 'consentTitle'
  | 'consentParagraph'
  | 'consentButton'
  | 'marginX'
  | 'ratio'
>;

export const EmbedGoogleMaps = ({
  componentLocale,
  fullWidth,
  url,
  ...rest
}: EmbedGoogleMapsProps): ReactElement | null => {
  const { locale } = useLocale(componentLocale);
  const { googleMaps } = useConsentContext();
  const { consent, setConsent } = googleMaps;

  const match = url?.match(REGEX);
  const place = match?.[1];
  const latitude = match?.[2];
  const longitude = match?.[3];

  const src = `https://maps.google.com/maps?q=${place}&ll=${latitude},${longitude}&z=15&hl=${locale}&output=embed`;

  return !url ? null : (
    <Embed
      ratio={{ base: 1, s: 16 / 9 }}
      src={src}
      consent={consent}
      setConsent={setConsent}
      consentTitle={TRANS.consentTitle[locale]}
      consentParagraph={TRANS.consentParagraph[locale]}
      consentButton={TRANS.consentButton[locale]}
      localStorageItem={LOCAL_STORAGE_ITEM}
      marginX={fullWidth ? 0 : fluid.zeroToHalf}
      marginY={'2em'}
      {...rest}
    />
  );
};
