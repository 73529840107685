import React, { ReactElement } from 'react';
import {
  ListItem as ChakraListItem,
  ListItemProps as ChakraListItemProps,
} from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';

export type ListItemProps = Omit<ChakraListItemProps, 'as'>;

export const ListItem = ({
  children,
  ...rest
}: ListItemProps): ReactElement | null =>
  isEmptyNode(children) ? null : (
    <ChakraListItem {...rest}>{children}</ChakraListItem>
  );
