import React, { ReactElement } from 'react';
import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';

export type QuoteProps = { source?: string | ReactElement } & Omit<
  BoxProps,
  'as'
>;

export const Quote = ({
  children,
  source,
  ...rest
}: QuoteProps): ReactElement | null =>
  isEmptyNode(children) ? null : !source ? (
    <Text
      as={'blockquote'}
      borderColor={'pinkTrillium'}
      borderStartWidth={3}
      display={'block'}
      fontStyle={'italic'}
      marginY={'1em'}
      paddingStart={'1em'}
      {...rest}
    >
      {children}
    </Text>
  ) : (
    <Box
      as={'figure'}
      borderColor={'pinkTrillium'}
      borderStartWidth={3}
      fontStyle={'italic'}
      marginY={'1em'}
      paddingStart={'1em'}
      {...rest}
    >
      <Box as={'blockquote'}>{children}</Box>
      <HStack
        as={'figcaption'}
        align={'flex-start'}
        fontSize={'0.9em'}
        fontStyle={'normal'}
        paddingTop={'0.5em'}
      >
        <Box>—</Box>
        <Box>{source}</Box>
      </HStack>
    </Box>
  );
