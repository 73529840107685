export const SIZES = ['small', 'medium', 'large'] as const;

export type Size = typeof SIZES[number];

export const MAP = {
  single: {
    small: 80,
    medium: 180,
    large: 280,
  },
  multiple: {
    small: 280,
    medium: 380,
    large: 580,
  },
};

export const REGEX =
  /(?:(?:https?:)?\/\/)?(?:(?:embed|open)\.)?(?:(?:acast\.com\/))(?:public\/)?(?:streams\/)?([\w-]+)(?:\/episodes\/|\/)?([\w-]+)?(?:[\w\/\.-]+)?/;

export const MOCK_EMBED = {
  single: {
    url: 'https://embed.acast.com/63caab8fa0216b00106012ba/63cb244cb2acc30010180c60',
    title: 'Aaa',
  },
  multiple: {
    url: 'https://embed.acast.com/63caab8fa0216b00106012ba',
    title: 'Aaa',
  },
};
