import React, { ReactElement } from 'react';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import { fluid } from '../../theme/fluid';
import { Contact, ContactProps } from '../Contact';
import { Heading } from '../Heading';
import { Image, ImageProps } from '../Image';
import { Link } from '../Link';
import { NestedLocale } from '../NestedLocale';
import { Paragraph } from '../Paragraph';
import { RichText, RichTextData } from '../RichText';

type PersonImageProps = {
  image: PersonProps['data']['image'];
  onRight?: PersonProps['imageOnRight'];
};

const PersonImage = ({
  image,
  onRight,
}: PersonImageProps): ReactElement | null => {
  return (
    <Box
      paddingY={'1em'}
      paddingStart={onRight ? '1em' : 0}
      paddingEnd={onRight ? 0 : '1em'}
      position={'relative'}
      _before={{
        backgroundColor: 'mintRose',
        content: '""',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        right: onRight ? fluid.fullToFullNegative : 0,
        bottom: 0,
        left: onRight ? 0 : fluid.fullToFullNegative,
        zIndex: -1,
      }}
    >
      <Flex
        align={'flex-start'}
        justify={'center'}
        backgroundColor={'white'}
        height={'100%'}
      >
        <Box maxWidth={{ base: '12em', m: '18em' }}>
          <Image image={image} />
        </Box>
      </Flex>
    </Box>
  );
};

export type PersonProps = {
  data: {
    name: string;
    pronouns?: string;
    bio?: RichTextData;
    image: ImageProps['image'];
    contact?: ContactProps['data'];
  };
  imageOnRight?: boolean;
};

export const Person = ({
  data,
  imageOnRight,
}: PersonProps): ReactElement | null => {
  const { name, pronouns, bio, image, contact } = data;
  const { email, ...rest } = contact ? contact : { email: '' };

  return !data ? null : (
    <SimpleGrid
      gridTemplateColumns={{
        base: '1fr',
        m: !bio?.raw ? '1fr 1fr' : imageOnRight ? '2fr 1fr' : '1fr 2fr',
        l: '1fr 1fr',
      }}
      marginBottom={'2em'}
      spacingX={'1em'}
      spacingY={0}
      sx={{
        '& p:first-of-type': { marginTop: 0 },
        '& p:last-of-type': { marginBottom: 0 },
      }}
    >
      {!imageOnRight && <PersonImage image={image} />}
      <Box paddingY={'0.5em'}>
        <Heading as={'p'} level={5}>
          {name}
        </Heading>
        {pronouns && (
          <NestedLocale componentLocale={'en'}>
            <Paragraph>{pronouns}</Paragraph>
          </NestedLocale>
        )}
        {email && <Link href={`mailto:${email}`}>{email}</Link>}
        {rest && <Contact data={rest} iconSize={'1.2em'} />}
        {bio?.raw && <RichText data={bio} />}
      </Box>
      {imageOnRight && <PersonImage image={image} onRight />}
    </SimpleGrid>
  );
};
