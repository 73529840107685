import React, { ReactElement, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { EventPostQuery } from '../../templates/EventPost';
import { useLocale } from '../../utils/hooks';
import { EN_SPACE } from '../../utils/string';
import { DateTime } from '../DateTime';
import { Hyphenate } from '../Hyphenate';
import { LinkCard } from '../LinkCard';
import { NestedLocale } from '../NestedLocale';
import { LinkCardEventPostVariant } from './misc';

export type LinkCardEventPostProps = {
  variant?: LinkCardEventPostVariant;
  parentSlug?: string;
} & Pick<
  EventPostQuery['contentfulPageEventPost'],
  | 'contentful_id'
  | 'slug'
  | 'language'
  | 'title'
  | 'start'
  | 'end'
  | 'location'
  | 'image'
>;

export const LinkCardEventPost = ({
  variant = 'wide',
  parentSlug,
  slug,
  language,
  title,
  start,
  end,
  location,
  image,
}: LinkCardEventPostProps): ReactElement => {
  const { locale } = useLocale(undefined);
  const href = useMemo(
    () =>
      parentSlug
        ? ['', locale, parentSlug, slug].join('/')
        : ['', locale, slug].join('/'),
    [locale, parentSlug, slug]
  );
  return variant === 'wideCondensed' ||
    variant === 'wideWithoutImageCondensed' ? (
    <Box
      {...(variant === 'wideWithoutImageCondensed'
        ? {
            borderColor: 'aquaOrchid',
            borderBottomWidth: 2,
          }
        : {})}
    >
      <LinkCard
        href={href}
        title={
          <NestedLocale componentLocale={language}>
            <Box fontSize={'1.1rem'} marginBottom={'0.4em'} isTruncated>
              {title}
            </Box>
          </NestedLocale>
        }
        subtitle={
          <Box isTruncated>
            {location && (
              <>
                <Box display={'inline'} fontSize={'0.9em'}>
                  {location}
                </Box>
                <Box
                  aria-hidden
                  color={'grayViolet'}
                  display={'inline'}
                  fontSize={'0.9em'}
                >
                  {EN_SPACE}|{EN_SPACE}
                </Box>
              </>
            )}
            <DateTime start={start} end={end} condensed />
          </Box>
        }
        image={variant === 'wideWithoutImageCondensed' ? undefined : image}
        imagePosition={'right'}
        breakpoint={'s'}
        borderPosition={'top'}
        borderWidth={'0.25rem'}
        extraSx={{
          base: {
            'height': '100%',
            '& .text': { height: '100%' },
            '& .image': {
              width: { base: '100%', s: '5em' },
              height: { base: 90, xs: '30vw', s: 'auto' },
            },
          },
        }}
      />
    </Box>
  ) : (
    <Box
      {...(variant === 'tallWithoutImage' || variant === 'wideWithoutImage'
        ? {
            borderColor: 'aquaOrchid',
            borderBottomWidth: 3,
            minHeight: '7em',
          }
        : {})}
    >
      <LinkCard
        href={href}
        title={
          <Box fontSize={'1.3rem'} marginBottom={'0.5em'}>
            <NestedLocale componentLocale={language}>
              <Hyphenate>{title}</Hyphenate>
            </NestedLocale>
          </Box>
        }
        tagline={<DateTime start={start} end={end} condensed />}
        subtitle={<Box fontSize={'0.9em'}>{location}</Box>}
        image={
          variant === 'tallWithoutImage' || variant === 'wideWithoutImage'
            ? undefined
            : image
        }
        imagePosition={
          variant === 'tall' || variant === 'tallWithoutImage' ? 'top' : 'right'
        }
        breakpoint={'s'}
        borderPosition={'top'}
        borderWidth={'0.5rem'}
        extraSx={{
          base: {
            'height': '100%',
            '& .text': { height: '100%' },
          },
        }}
      />
    </Box>
  );
};
