import React, { ReactElement } from 'react';
import { fluid } from '../../theme/fluid';
import { EmbedWithoutCookies, EmbedWithoutCookiesProps } from '../Embed';
import { MAP, REGEX } from './misc';

export type EmbedAcastProps = {
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
  url: string;
} & Omit<EmbedWithoutCookiesProps, 'src'>;

export const EmbedAcast = ({
  fullWidth,
  size = 'small',
  url,
  ...rest
}: EmbedAcastProps): ReactElement | null => {
  const match = url?.match(REGEX);
  const show = match?.[1];
  const episode = match?.[2];
  const isFeed = !episode;
  const src = isFeed
    ? `https://embed.acast.com/${show}?theme=light&feed=true&episode-order=asc`
    : `https://embed.acast.com/${show}/${episode}?theme=light&`;

  const height = isFeed ? MAP['multiple'][size] : MAP['single'][size];

  return !src ? null : (
    <EmbedWithoutCookies
      src={src}
      height={height}
      marginX={fullWidth ? 0 : fluid.zeroToHalf}
      marginY={'2em'}
      {...rest}
    />
  );
};
