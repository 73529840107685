import React, { ReactElement } from 'react';
import GatsbyLink from 'gatsby-link';
import { Box, Flex, FlexProps, Spacer } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Link } from '../Link';
import { TRANS } from './misc';

export type LinksPrevNextProps = {
  componentLocale?: Locale;
  prev?: string;
  next?: string;
  variant?: 'prev-next' | 'newer-older';
} & FlexProps;

export const LinksPrevNext = ({
  componentLocale,
  prev,
  next,
  variant,
  ...rest
}: LinksPrevNextProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return (
    <Flex color={'tealCarnation'} {...rest}>
      <Box
        _hover={{
          '& .text': { textDecoration: 'underline' },
          '& .icon': { transform: 'translate(-0.3em, 0)' },
        }}
      >
        {prev && (
          <Link as={GatsbyLink} to={prev} lang={lang} color={'inherit'}>
            <ArrowLeftIcon
              aria-hidden
              className={'icon'}
              fontSize={'0.8em'}
              marginRight={'0.3em'}
              transitionDuration={'normal'}
            />
            <Box as={'span'} className={'text'}>
              {variant === 'newer-older'
                ? TRANS.newer[locale]
                : TRANS.previous[locale]}
            </Box>
          </Link>
        )}
      </Box>
      <Spacer />
      <Box
        _hover={{
          '& .text': { textDecoration: 'underline' },
          '& .icon': { transform: 'translate(0.3em, 0)' },
        }}
      >
        {next && (
          <Link as={GatsbyLink} color={'inherit'} to={next} lang={lang}>
            <Box as={'span'} className={'text'}>
              {variant === 'newer-older'
                ? TRANS.older[locale]
                : TRANS.next[locale]}
            </Box>
            <ArrowRightIcon
              aria-hidden
              className={'icon'}
              fontSize={'0.8em'}
              marginLeft={'0.5em'}
              transitionDuration={'normal'}
            />
          </Link>
        )}
      </Box>
    </Flex>
  );
};
