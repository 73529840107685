import React, { ReactElement } from 'react';
import { Center } from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { TRANS } from './misc';

export type ComingSoonProps = {
  componentLocale?: Locale;
};

export const ComingSoon = ({
  componentLocale,
}: ComingSoonProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);

  return (
    <Center lang={lang}>
      <Center
        borderColor={'mintRose'}
        borderWidth={'0.25rem'}
        padding={'3em'}
        width={'100%'}
      >
        {TRANS.comingSoon[locale]}
      </Center>
    </Center>
  );
};
