export const getNextDivisor = (
  number: number,
  divisor: number,
  approximate?: boolean
): number => {
  /* if the number or the divisor are 2 or less, the next divisor is 1 */
  if (number < 2 || divisor < 2) {
    return 1;
  }

  /* for efficiency, start looking for divisors at the current divisor or just half the original number, whichever is smaller (for example, a divisor of 10 can never be 9, 8, 7, or 6) */
  divisor = Math.min(divisor, Math.ceil(number / 2));

  /* normally, we're looking for actual divisors, so when number modulo divisor is 0 */
  if (!approximate) {
    while (divisor > 1 && number % divisor !== 0) {
      divisor--;
    }
  } else {
    /* however, to allow for prime numbers or any other situations when we wish to approximate the result, in those cases we'll accept it when number modulo divisor is small enough (less than half of the divisor) */
    while (divisor > 3 && number % divisor < divisor / 2) {
      divisor--;
    }
  }

  return divisor;
};
