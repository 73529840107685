import React, { ReactElement, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import { LocaleContext } from '../../context/LocaleContext';
import { useLocale } from '../../utils/hooks';
import { isEmptyNode } from '../../utils/other';
import { LocaleOrUndefined } from '../../utils/types';

export type NestedLocaleProps = {
  componentLocale: LocaleOrUndefined;
  children: ReactNode;
};

export const NestedLocale = ({
  componentLocale,
  children,
}: NestedLocaleProps): ReactElement | null => {
  const { lang } = useLocale(componentLocale);

  return isEmptyNode(children) ? null : !lang ? (
    <>{children}</>
  ) : (
    <LocaleContext value={componentLocale}>
      <Box lang={componentLocale}>{children}</Box>
    </LocaleContext>
  );
};
