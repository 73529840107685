import React, { ReactElement } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';

export type SuperscriptProps = Omit<TextProps, 'as'>;

export const Superscript = ({
  children,
  ...rest
}: SuperscriptProps): ReactElement | null =>
  isEmptyNode(children) ? null : (
    <Text
      as={'sup'}
      fontSize={'75%'}
      lineHeight={0}
      paddingX={'0.2em'}
      position={'relative'}
      top={'-0.5em'}
      verticalAlign={'baseline'}
      {...rest}
    >
      {children}
    </Text>
  );
