import React, { ReactElement } from 'react';
import {
  ListProps as ChakraListProps,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';
import { ORDERED_LIST_STYLE_TYPES, UNORDERED_LIST_STYLE_TYPES } from './misc';

export type ListProps = { level?: number; ordered?: boolean } & Omit<
  ChakraListProps,
  'as'
>;

export const List = ({
  children,
  level,
  ordered,
  ...rest
}: ListProps): ReactElement | null => {
  const styleTypes = ordered
    ? ORDERED_LIST_STYLE_TYPES
    : UNORDERED_LIST_STYLE_TYPES;

  const styleType = !level
    ? styleTypes[0]
    : level < styleTypes.length
    ? styleTypes[level]
    : styleTypes[styleTypes.length - 1];

  return isEmptyNode(children) ? null : ordered ? (
    <OrderedList listStyleType={styleType} role={undefined} {...rest}>
      {children}
    </OrderedList>
  ) : (
    <UnorderedList listStyleType={styleType} role={undefined} {...rest}>
      {children}
    </UnorderedList>
  );
};
