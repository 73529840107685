import React, {
  Dispatch,
  ReactChild,
  ReactElement,
  SetStateAction,
} from 'react';
import {
  AspectRatio,
  AspectRatioProps,
  Box,
  Center,
  Icon,
} from '@chakra-ui/react';
import { FaCookieBite } from 'react-icons/fa';
import { AnimatePresence, motion } from 'framer-motion';
import { useResizeDetector } from 'react-resize-detector';
import { fluid } from '../../theme/fluid';
import { Button } from '../Button';
import { Paragraph } from '../Paragraph';
import { Strong } from '../Strong';
export {
  EmbedWithoutCookies,
  type EmbedWithoutCookiesProps,
} from './withoutCookies';

export type EmbedProps = {
  src: string;
  title: string;
  consent: boolean;
  setConsent: Dispatch<SetStateAction<boolean>>;
  consentTitle: ReactChild;
  consentParagraph: ReactChild;
  consentButton: ReactChild;
  rememberConsent?: boolean;
  localStorageItem?: string;
  preventScroll?: boolean;
} & Omit<AspectRatioProps, 'as'>;

const ConsentButton = ({
  setConsent,
  consentButton,
  rememberConsent,
  localStorageItem,
}: Pick<
  EmbedProps,
  'setConsent' | 'consentButton' | 'rememberConsent' | 'localStorageItem'
>) => (
  <Button
    fontSize={'inherit'}
    onClick={() => {
      setConsent(true);
      rememberConsent &&
        localStorageItem &&
        localStorage.setItem(localStorageItem, 'true');
    }}
  >
    {consentButton}
  </Button>
);

export const Embed = ({
  src,
  title,
  consent,
  setConsent,
  consentTitle,
  consentParagraph,
  consentButton,
  rememberConsent,
  localStorageItem,
  preventScroll = true,
  ...rest
}: EmbedProps): ReactElement | null => {
  const buttonProps = {
    setConsent,
    consentButton,
    rememberConsent,
    localStorageItem,
  };

  const { height: minHeight, ref } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
    refreshMode: 'debounce',
    refreshRate: 0,
  });

  return (
    <AnimatePresence exitBeforeEnter>
      <AspectRatio
        key={consent.toString()}
        as={motion.div}
        initial={!consent ? 'shown' : 'hidden'}
        animate={'shown'}
        exit={'hidden'}
        variants={{
          hidden: {
            opacity: 0,
            transition: {
              ease: 'easeOut',
              duration: 0.25,
            },
          },
          shown: {
            opacity: 1,
            transition: {
              ease: 'easeIn',
              duration: 0.5,
            },
          },
        }}
        minHeight={minHeight}
        overflow={'hidden'}
        {...rest}
      >
        {!consent ? (
          <Box
            backgroundColor={'white'}
            borderColor={'mintRose'}
            borderWidth={4}
          >
            <Box
              {...(preventScroll
                ? { ref: ref }
                : {
                    overflow: 'auto',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  })}
            >
              <Center
                minHeight={'100%'}
                paddingX={fluid.fullToFixed}
                paddingY={'1.5em'}
              >
                <Box fontSize={'0.8em'}>
                  <Paragraph marginTop={0}>
                    <Strong>
                      <Icon
                        as={FaCookieBite}
                        marginEnd={'0.5em'}
                        marginBottom={'-0.25em'}
                      />
                      {consentTitle}
                    </Strong>
                  </Paragraph>
                  {preventScroll ? null : <ConsentButton {...buttonProps} />}
                  <Paragraph>{consentParagraph}</Paragraph>
                  <ConsentButton {...buttonProps} />
                </Box>
              </Center>
            </Box>
          </Box>
        ) : (
          <Box
            as={'iframe'}
            allowFullScreen
            frameBorder={0}
            src={src}
            title={title}
          />
        )}
      </AspectRatio>
    </AnimatePresence>
  );
};
