import React, { ReactElement } from 'react';
import { Box, BoxProps, Collapse, Flex, useDisclosure } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useLocale } from '../../utils/hooks';
import { isEmptyNode } from '../../utils/other';
import { Locale } from '../../utils/types';
import { Button } from '../Button';
import { TRANS } from './misc';

export type DisclosureProps = {
  componentLocale?: Locale;
  title: string;
} & BoxProps;

export const Disclosure = ({
  children,
  componentLocale,
  title,
  ...rest
}: DisclosureProps): ReactElement | null => {
  const { locale } = useLocale(componentLocale);
  const { isOpen, onToggle } = useDisclosure();

  return isEmptyNode(children) ? null : (
    <Box marginY={'1rem'} {...rest}>
      <Button
        aria-expanded={isOpen}
        aria-controls={title + locale}
        onClick={onToggle}
        borderRadius={0}
        display={'block'}
        paddingX={'0.7em'}
        paddingY={'0.5em'}
        width={'100%'}
        zIndex={1}
      >
        <Flex width={'100%'} align={'baseline'} justify={'space-between'}>
          <Box whiteSpace={'normal'} textAlign={'left'} marginEnd={'1rem'}>
            {title}
          </Box>
          <Box aria-hidden>
            <Box as={'span'}>{TRANS[isOpen ? 'hide' : 'show'][locale]}</Box>
            <AddIcon
              marginStart={'0.7rem'}
              marginBottom={'0.1em'}
              transform={isOpen ? 'rotate(45deg)' : 'rotate(0)'}
              transitionProperty={'transform'}
              transitionDuration={'slow'}
            />
          </Box>
        </Flex>
      </Button>
      <Box
        id={title + locale}
        borderColor={'tealCarnation'}
        borderBottomWidth={2}
        position={'relative'}
        bottom={2}
      >
        <Collapse in={isOpen} animateOpacity>
          <Box
            marginTop={1}
            paddingX={'0.7em'}
            paddingY={'0.5em'}
            sx={{
              '& p:first-of-type': {
                marginTop: 0,
              },
              '& p:last-of-type': {
                marginBottom: 0,
              },
            }}
          >
            {children}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};
