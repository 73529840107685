export const isPrime = (number: number): boolean => {
  if (number < 2) {
    return false;
  }

  let n = 2;
  while (n <= number / 2) {
    if (number % n === 0) {
      return false;
    }
    n++;
  }

  return true;
};
