import React, { ReactElement } from 'react';
import {
  Code as ChakraCode,
  CodeProps as ChakraCodeProps,
  useToken,
} from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';

export type CodeProps = Omit<ChakraCodeProps, 'as'>;

export const Code = ({ children, ...rest }: CodeProps): ReactElement | null => {
  const [grayViolet] = useToken('colors', ['grayViolet']);

  return isEmptyNode(children) ? null : (
    <ChakraCode
      backgroundColor={grayViolet + '20'}
      color={'inherit'}
      display={'inline'}
      fontSize={'0.9em'}
      marginX={'0.1em'}
      paddingX={'0.4em'}
      paddingBottom={2}
      {...rest}
    >
      {children}
    </ChakraCode>
  );
};
